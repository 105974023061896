@import 'antd/dist/antd.css';
@import './styles.css';


.ant-card-body {
  padding: 9px;
}

.ant-card {
  margin-bottom: 10px;
}