.header {
  position: fixed;
  width: 100%;
  top: 0px;
  z-index: 1;
  display: flex;
  padding: 0 24px;
  align-items: center;
}

.nav-title {
  margin-left: 1.5rem;
  font-weight: bold;
  color: white;
  margin-right: auto;
}

.m-nav-btns {
  color: white;
  font-weight: bold;
}

@media (max-width: 400px) {
  .nav-title {
    font-size: 0.7rem;
  }
}

@media (max-width: 768px) {
  .lgMenu,
  .mdMenu {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .nav-title {
    font-size: 1rem;
  }
  .smMenu,
  .lgMenu {
    display: none;
  }
  .mdMenu {
    display: block;
  }
}

@media (min-width: 992px) {
  .smMenu,
  .mdMenu {
    display: none;
  }
  .lgMenu {
    display: block;
  }
}

@media (min-width: 992px) {
  .nav-title-text {
    font-size: 1.2rem;
  }
}
