.ant-message-custom-content > .anticon,
.ant-pagination-item-link > .anticon {
  vertical-align: 0.05em;
}

.align-btn-icon .anticon,
.ant-btn-loading-icon .anticon {
  vertical-align: 0.05em;
}

.ant-modal-title {
  font-weight: bold;
  font-size: 1.2rem;
}

.ant-table-thead .ant-table-cell {
  font-weight: bold;
}

.s-hover-target {
  transition: all 400ms;
  opacity: 0;
}

.s-hover-parent:hover .s-hover-target {
  opacity: 1;
}

.riz-spinner {
  animation: spinning-animation 1s linear infinite;
}

@keyframes spinning-animation {
  to {
    transform: rotate(360deg);
  }
}

.label-mb-0 {
  margin-bottom: 0;
}

.z-h-fix .ant-table-content {
  overflow: auto !important;
  max-height: 2750;
}
.m-capitalize,
.m-capitalize > .ant-select-item-option-content {
  text-transform: capitalize;
}

.m-video {
  height: 330px;
}

@media screen and (max-width: 992px) {
  .m-mt-video {
    margin-top: 2rem;
  }
  .m-video {
    height: 450px;
  }
}

@media screen and (max-) {
}

.m-list-bgc .ant-list-header {
  background-color: #f6f6f6;
}

.ant-popover-title {
  background-color: #f6f6f6;
}

.ant-table-row:hover .m-delete-hover {
  color: black;
}

.remove-padding .ant-comment-inner {
  padding: 0;
}
