.int-cols-bg {
  background-color: #cbcbcb;
}

.description-col-bg {
  background-color: #fef7c9;
}

.submitted-to-bg {
  background-color: #9ec0e5;
}

.ant-table-thead .ant-table-cell {
  font-weight: normal;
}
